var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"value":_vm.partner_name,"disabled":""}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask_on_type),expression:"mask_on_type"}],class:_vm.$style.item,attrs:{"value":_vm.new_org.inn_organization,"error":!!_vm.is_inn_error_msg,"error-messages":_vm.is_inn_error_msg,"label":"ИНН","require":""},on:{"input":_vm.upd_field_inn}}),_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.kpp_mask),expression:"kpp_mask"}],key:_vm.org_type,class:_vm.$style.item,attrs:{"value":_vm.new_org.kpp_organization,"rules":[
            function (v) { return _vm.kpp_rules_by_type(_vm.org_type, v); }
          ],"label":"КПП","require":_vm.is_require_kpp},on:{"input":function($event){return _vm.upd_field('kpp_organization', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow, 'pl-2']},[_c('v-radio-group',{class:_vm.$style.item,attrs:{"value":_vm.new_org.organization_type,"row":""},on:{"change":_vm.org_type_change}},_vm._l((_vm.radios),function(radio,i){return _c('Radio',{key:i,attrs:{"label":radio.label,"value":radio.value}})}),1),(_vm.can_show_ip_scan)?_c('FileInput',{class:_vm.$style.item,attrs:{"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"accept":".pdf, .jpg, .jpeg,","label":"Свидетельство о регистрации ИП","persistent-hint":"","hint":"Загрузите файл одного из форматов: PDF, JPG, JPEG, не более 10Мб","require":""},on:{"change":function($event){return _vm.upd_file_field('ip_scan_file', $event)}}}):_vm._e()],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_org.name_organization,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Наименование организации","require":""},on:{"input":function($event){return _vm.upd_field('name_organization', $event)}}}),_c('Datepicker',{attrs:{"date_prop":_vm.new_org.date_registration_company,"classes":_vm.$style.item,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Дата регистрации компании","required":""},on:{"date_changed":function($event){return _vm.upd_field('date_registration_company', $event)}}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_org.city,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Город","require":""},on:{"input":function($event){return _vm.upd_field('city', $event)}}}),_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_org.region,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Регион","require":""},on:{"input":function($event){return _vm.upd_field('region', $event)}}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"value":_vm.new_org.federal_district,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Федеральный округ","require":""},on:{"input":function($event){return _vm.upd_field('federal_district', $event)}}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"value":_vm.new_org.general_manager,"label":"Генеральный директор","rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"require":""},on:{"input":function($event){return _vm.upd_field('general_manager', $event)}}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"value":_vm.new_org.name_signatory,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"ФИО подписанта","require":""},on:{"input":function($event){return _vm.upd_field('name_signatory', $event)}}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('FileInput',{class:_vm.$style.item,attrs:{"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Доверенность","require":"","accept":".pdf, .jpg, .jpeg","persistent-hint":"","hint":"Загрузите файл одного из форматов: PDF, JPG, JPEG, не более 10Мб"},on:{"change":function($event){return _vm.upd_file_field('attorney_file', $event)}}}),_c('Select',{attrs:{"value":_vm.new_org.basis_document,"items":_vm.basic_docs,"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Действует на основании","blue_icon":"","require":""},on:{"input":function($event){return _vm.upd_field('basis_document', $event)}}})],1)]),_c('v-col',{attrs:{"cols":"7"}},[_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-auto",attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")]),_c('v-btn',{attrs:{"color":"accent darken-4","depressed":"","type":"submit","disabled":!_vm.form_valid}},[_vm._v(" Продолжить ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }